var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event},"dblclick:row":_vm.dblClick},scopedSlots:_vm._u([{key:"item.mouPeriodMonths",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.mouPeriodMonths)+" Bulan ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(((_vm.dateFormat(item.mouPeriodStart)) + " sd " + (_vm.dateFormat(item.mouPeriodEnd))))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.createdAt)+" ")])]}},{key:"item.approvalStatus",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-decoration-underline",class:_vm.checkStatus(item.approvalStatus),attrs:{"text":"","small":"","color":"red"},on:{"click":function($event){return _vm.onClickApprovalStatus(item)}}},[_vm._v(" "+_vm._s(item.approvalStatus)+" ")])]}},{key:"item.actionColumn",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.routeNavigate(item, { action: 'view' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.VIEW_DETAIL))])]),((item.canEdit && item.activeStatus === 'DRAFT')
          || (item.canEdit && item.activeStatus === 'ACTIVE')
          && (_vm.menuFunctions.ADDENDUM_MOU_SHIPPER || _vm.menuFunctions.ADDENDUM_MOU_TRANSPORTER))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.clickedEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[(item.activeStatus === 'DRAFT')?_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))]):_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.ADDENDUM))])]):_vm._e(),(((item.canEdit && item.activeStatus === 'DRAFT') || item.activeStatus === 'WAITING_ACTIVE') && _vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showDialogDeleteConfirm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e(),(item.activeStatus === 'ACTIVE' && !item.isDownload)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":item.donwloading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadMou(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DOWNLOAD))])]):_vm._e(),(item.activeStatus === 'ACTIVE' && !item.isUploading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","x-small":""},on:{"click":function($event){_vm.$refs['file'+item.id].$refs.input.click()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-file-input',{ref:'file'+item.id,staticClass:"d-none",attrs:{"label":_vm.$_strings.userAdmin.IMAGE_INPUT_LABEL,"clearable":false,"type":"file","accept":".pdf"},on:{"change":function ($event) { return _vm.setFileInput($event, item); }}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.UPLOAD))])]):_vm._e(),(item.activeStatus === 'ACTIVE' && !item.isDownload)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":item.downloadTemp,"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadMouTemplate(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Template")])]):_vm._e(),(item.isUploading)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"value":item.progressUploading,"color":"primary","size":"18"}}):_vm._e(),(item.activeStatus === 'ACTIVE' && _vm.mouBy === 'shipper')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.routeNavigate(item, { action: 'set-vehicle-number' }, 2)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-car-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.SET_VEHICLE_NUMBER))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.mou.MOU_SHIPPER)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)}),_c('Dialog',{ref:"confirm"}),_c('DialogApprovalHistory',{ref:"dialogApprovalHistory"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }